<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 16 16"
    xml:space="preserve"
  >
    <path
      d="M10.69 14.99l-1.5-4.79A2.51 2.51 0 0 0 10.5 8a2.5 2.5 0 0 0-5 0c0 .95.53 1.77 1.31 2.2l-1.5 4.79A7.483 7.483 0 0 1 .5 8C.5 3.86 3.86.5 8 .5s7.5 3.36 7.5 7.5c0 3.19-2 5.92-4.81 6.99z"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
    />
  </svg>
</template>
