<template>
  <div>
    <a
      href="https://twitter.com/tldrrun?ref_src=twsrc%5Etfw"
      class="twitter-follow-button"
      data-size="large"
      data-dnt="true"
      data-show-count="true"
      >Follow @tldrrun</a
    >
  </div>
</template>

<style scoped>
div {
  text-align: center;
}
</style>
