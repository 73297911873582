<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 511.988 511.988"
    style="enable-background:new 0 0 511.988 511.988;"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M394.655,202.654H95.988c-17.643,0-32,14.357-32,32v21.333c0,76.117,42.048,145.152,109.717,180.16
			c5.227,2.688,11.669,0.661,14.379-4.587c2.688-5.227,0.661-11.669-4.587-14.379c-60.544-31.317-98.176-93.077-98.176-161.195
			v-21.333c0-5.888,4.779-10.667,10.667-10.667h298.667c5.888,0,10.667,4.779,10.667,10.667v21.333
			c0,68.117-37.632,129.877-98.197,161.173c-5.248,2.709-7.275,9.152-4.587,14.379c1.899,3.669,5.632,5.781,9.493,5.781
			c1.664,0,3.328-0.384,4.885-1.195c67.691-34.987,109.739-104,109.739-180.139v-21.333
			C426.655,217.011,412.297,202.654,394.655,202.654z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M489.865,422.558c-1.664-3.989-5.547-6.592-9.856-6.592H10.676c-4.309,0-8.192,2.603-9.856,6.592
			c-1.664,3.989-0.747,8.576,2.304,11.627l23.915,23.936c14.123,14.101,32.853,21.867,52.8,21.867h330.965
			c19.947,0,38.699-7.765,52.843-21.867l23.915-23.936C490.612,431.134,491.529,426.547,489.865,422.558z M448.543,443.038
			c-10.069,10.069-23.488,15.616-37.717,15.616H79.839c-14.229,0-27.648-5.547-37.717-15.616l-5.717-5.717H454.26L448.543,443.038z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M491.359,253.022c-28.736-18.475-74.645,1.579-79.787,3.904c-5.355,2.453-7.744,8.768-5.291,14.144
			c2.453,5.376,8.747,7.744,14.144,5.291c10.283-4.672,43.264-15.744,59.392-5.355c7.275,4.672,10.837,13.739,10.837,27.648
			c0,42.709-86.144,68.523-119.403,74.88l-7.552,1.493c-5.781,1.131-9.536,6.741-8.405,12.523c1.003,5.077,5.461,8.597,10.453,8.597
			c0.683,0,1.365-0.064,2.069-0.213l7.488-1.472c5.589-1.067,136.683-26.773,136.683-95.808
			C511.988,277.193,505.034,261.854,491.359,253.022z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M328.372,113.31c17.344-21.675,17.344-55.616,0-77.291c-3.691-4.608-10.368-5.376-14.997-1.664
			c-4.608,3.691-5.355,10.389-1.664,14.997c10.987,13.717,10.987,36.907-0.021,50.667c-17.365,21.675-17.365,55.616,0,77.291
			c2.112,2.645,5.205,4.011,8.341,4.011c2.325,0,4.693-0.768,6.656-2.347c4.608-3.691,5.355-10.411,1.664-14.997
			C317.343,150.259,317.343,127.07,328.372,113.31z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M264.287,113.31c17.365-21.675,17.365-55.616,0-77.291c-3.669-4.608-10.389-5.355-14.997-1.664
			c-4.608,3.691-5.355,10.411-1.664,14.997c11.008,13.717,11.008,36.907-0.021,50.667c-17.344,21.675-17.344,55.616,0,77.291
			c2.112,2.645,5.205,4.011,8.341,4.011c2.325,0,4.672-0.768,6.656-2.347c4.608-3.691,5.355-10.389,1.664-14.997
			C253.279,150.259,253.279,127.07,264.287,113.31z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M200.372,113.331c17.344-21.675,17.344-55.616,0-77.291c-3.669-4.608-10.368-5.376-14.997-1.664
			c-4.587,3.669-5.333,10.389-1.664,14.976c10.987,13.717,10.987,36.907-0.021,50.667c-17.365,21.675-17.365,55.616,0,77.291
			c2.112,2.645,5.205,4.011,8.341,4.011c2.325,0,4.693-0.768,6.656-2.325c4.608-3.691,5.355-10.411,1.664-14.997
			C189.343,150.259,189.343,127.091,200.372,113.331z"
        />
      </g>
    </g>
  </svg>
</template>
